<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>CERRAR QR</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-form ref="form_cerrar">
            <v-row no-gutters v-if="registro.gescierre">
              <v-col cols="12" md="6">
                <texto2 :colores="colores" :titulo="'PRÓRROGA'" :descripcion="(registro.gescierre)?registro.gescierre.prorroga.descripcion:''"></texto2>
              </v-col>
            </v-row>
            <v-row v-else no-gutters>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="form_cerrado.prorroga_id" v-on:input="input_prorroga(form_cerrado.prorroga_id)" :rules="[rules.requerido]" :items="items.tiposi" item-text="descripcion" item-value="id" label="PRÓRROGA" :color="colores.primario"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-if="form_cerrado.prorroga_id === 29">
              {{ (registro.quejaexpres_id == 30 || registro.quejaexpres_id == null)? rec_function_replaceall(registro.fechaparacerrarexterno, ' 00:00:00', '') : rec_function_replaceall(registro.fechaparacerrarexpres, ' 00:00:00', '') }} LA PRÓRROGA SE EXTENDERA 5 DIAS HÁBILES {{ registro.fechaProrrogaAct.substr(0, 10)}}
              </v-col>
            </v-row>
            <div v-if="(registro.gescierre === null && form_cerrado.prorroga_id === 30) || (registro.gescierre !== null)">
            <v-row>
              <v-col >
                    <v-switch
                    v-model="switch1"
                    color="success"
                    label="Realizar Marcación"
                    @change="seleccionMarcacion('switch')"
                    ></v-switch>
                  </v-col>
            </v-row>
            <v-row no-gutters v-if="showMarcacion">
              <v-col cols="12" align-self="center">
                <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '16px' }]"><b>MARCACIÓN</b></span></v-toolbar>
                <v-radio-group v-model="form_duplicado.marcacion_id" :rules="[rules.requerido]" hide-details dense>
                  <div v-for="(item, index) in items_marcacion" :key="index">
                  <v-radio :color="colores.primario" :label="item.descripcion" :value="item.id"></v-radio>
                  <v-divider></v-divider>
                  </div>
                </v-radio-group>
              </v-col>
            </v-row>
              <desistimientoForm :session="session" :registro="registro" :form_cerrado="form_cerrado" :items="items" :colores="colores" :rules="rules" @respuestaFinalFile="actualizarRepuestaFinal"></desistimientoForm>
              <br>
              <consumidorForm v-if="registro.instancia_id === 82" :form_consumidor="form_consumidor" :items="items" :colores="colores" :rules="rules"></consumidorForm>
              <br>
              <tutelaForm v-if="registro.tipo_id === 77" :form_tutela="form_tutela" :items="items" :colores="colores" :rules="rules"></tutelaForm>
              <br>
              <v-card>
                  <v-toolbar dense flat :color="colores.primario" dark><span :style="[{'font-size': '20px'}]">ANÁLISIS DEL REQUERIMIENTO</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="abrirAnalisis"><v-icon>mdi-plus</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-list>
                      <div v-for="(item, index) in form_analisis" :key="index">
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-avatar :color="colores.primario" class="white--text">{{ index + 1 }}</v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters>
                                <v-col cols="12" md="6">CAUSA RAÍZ REQUERIMIENTO </v-col>
                                <v-col cols="12" md="6">{{ item.causal_descripcion }} </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col cols="12" md="6">MOTIVO CAUSA </v-col>
                                <v-col cols="12" md="6">{{ item.motivo_descripcion }} </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col cols="12" md="6">SUBMOTIVO CAUSA</v-col>
                                <v-col cols="12" md="6">{{ item.submotivo_descripcion }} </v-col>
                              </v-row>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-row no-gutters v-if="item.responsablequeja_descripcion">
                                <v-col cols="12" md="6" >RESPONSABLE QUEJA</v-col>
                                <v-col cols="12" md="6">{{ item.responsablequeja_descripcion }} </v-col>
                              </v-row>
                              <v-row no-gutters v-if="item.causalqueja_descripcion">
                                <v-col cols="12" md="6">CAUSA QUEJA</v-col>
                                <v-col cols="12" md="6">{{ item.causalqueja_descripcion }} </v-col>
                              </v-row>
                              <v-row no-gutters v-if="item.marcacion_descripcion">
                                <v-col cols="12" md="6">MARCACIÓN SEGUIMIENTO</v-col>
                                <v-col cols="12" md="6">{{ item.marcacion_descripcion }} </v-col>
                              </v-row>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn @click="eliminar_analisis(index)" icon><v-icon color="red">mdi-delete</v-icon></v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                      </div>
                  </v-list>
                  <span class="red--text" v-if="showAnalisis">DEBE AGREGAR UN ANÁLISIS DEL REQUERIMIENTO</span>
                </v-card-text>
              </v-card>
              <br><br>
              <dialogoAnalisis :dialogAnilisis="dialogAnilisis" :tokenAnalisis="tokenAnalisis" :items="items" :colores="colores" :rules="rules" @closed_analisis="cerrar_analisis" @add_analisis="agregar_analisis"></dialogoAnalisis>
            </div>
            <br>
            <v-row no-gutters v-if="session.area !== 17 && this.session.id === this.idEscalado">
                <v-col cols="12" class="text-center">
                  <!-- BOTON GUARDAR  CERRAR QR -->
                  <v-btn @click="validar" block large :color="colores.black" dark>CERRAR Y DAR RESPUESTA CON LA QR</v-btn>
                </v-col>
              </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-sheet>
  <br><br>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import recGestiones from '@/js/rec_gestiones.js'
import recFunciones from '@/js/rec_funciones.js'
import recListado from '@/js/rec_listado.js'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
// import desistimientoForm from '@/components/crm/form_qr_cerrar_desistimientosComponent.vue'
// import consumidorForm from '@/components/crm/form_qr_cerrar_consumidorComponent.vue'
// import tutelaForm from '@/components/crm/form_qr_cerrar_tutelaComponent.vue'
// import dialogoAnalisis from '@/components/crm/form_qr_cerrar_dialogoComponent.vue'

export default {
  name: 'form_qr_cierreComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue'),
    desistimientoForm: () => import('@/components/crm/form_qr_cerrar_desistimientosComponent.vue'),
    consumidorForm: () => import('@/components/crm/form_qr_cerrar_consumidorComponent.vue'),
    tutelaForm: () => import('@/components/crm/form_qr_cerrar_tutelaComponent.vue'),
    dialogoAnalisis: () => import('@/components/crm/form_qr_cerrar_dialogoComponent.vue'),
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue')
  },
  mixins: [recFunciones, recGestiones, recListado],
  props: ['colores', 'registro', 'rules', 'gestion_id', 'session', 'form_otro', 'items', 'token_area'],
  data: () => ({
    showAnalisis: false,
    dialogAnilisis: false,
    tokenAnalisis: 0,
    form_consumidor: { gestion_id: 0, aceptacion_id: null, rectificacion_id: null },
    form_tutela: { gestion_id: 0, resultado_id: 0 },
    form_cerrado: { gestion_id: 0, desistimiento_id: null, prorroga_id: null, procorreoenvio_id: 0, fincorreoenvio_id: 0, resumen: '', favorabilidad_id: null, fechaparacerrarprorroga: null },
    form_analisis: [],
    dialogo: { respuestaFinalFile: false, incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: [],
    switch1: false,
    opcion: 0,
    items_marcacion: [],
    showMarcacion: false,
    form_duplicado: { marcacion_id: null },
    estadoRespuestaFinalFile: 0,
    idEscalado: ''
  }),
  watch: {
    token_area: {
      immediate: true,
      handler (newVal, oldVal) {
        this.inicializar_cierre()
        for (let i = 0; i < this.registro.escalamientos.length; i++) {
          if (this.registro.escalamientos[i].fecha_cerrado === null) {
            this.idEscalado = this.registro.escalamientos[i].id_escaladoa
          }
        }
      }
    }
  },
  methods: {
    validar () {
      this.showAnalisis = false
      if (this.$refs.form_cerrar.validate()) {
        if (this.estadoRespuestaFinalFile === 0 && this.form_cerrado.prorroga_id === 30) {
          this.dialogo.respuestaFinalFile = true
        } else {
          if (this.registro.gescierre !== null || this.form_cerrado.prorroga_id === 30) {
            if (this.form_analisis.length > 0) {
              this.dialogo.pregunta = true
            } else {
              this.showAnalisis = true
              this.dialogo.incompleto = true
            }
          } else {
            this.form_cerrado.fechaparacerrarprorroga = this.registro.fechaProrrogaAct
            this.dialogo.pregunta = true
          }
        }
      } else {
        this.dialogo.incompleto = true
        if (this.estadoRespuestaFinalFile === 0 && this.form_cerrado.prorroga_id === 30) {
          this.dialogo.respuestaFinalFile = true
        }
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_cierre_tipo() // this.rec_gestion_cierre_odt()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    abrirAnalisis () {
      this.tokenAnalisis = this.tokenAnalisis + 1
      this.dialogAnilisis = true
    },
    cerrar_analisis () {
      this.dialogAnilisis = false
    },
    agregar_analisis (objCausal, objMotivo, objSubmotivo, objResponsable, objCausalqueja, objMarcacion) {
      if (objResponsable == null) objResponsable = { id: null, descripcion: null }
      if (objCausalqueja == null) objCausalqueja = { id: null, descripcion: null }
      if (objMarcacion == null) objMarcacion = { id: null, descripcion: null }
      this.showAnalisis = false
      this.form_analisis.push({ gestion_id: this.gestion_id, causal_id: objCausal.id, motivo_id: objMotivo.id, submotivo_id: objSubmotivo.id, responsablequeja_id: objResponsable.id, causalqueja_id: objCausalqueja.id, marcacion_id: objMarcacion.id, causal_descripcion: objCausal.descripcion, motivo_descripcion: objMotivo.descripcion, submotivo_descripcion: objSubmotivo.descripcion, responsablequeja_descripcion: objResponsable.descripcion, causalqueja_descripcion: objCausalqueja.descripcion, marcacion_descripcion: objMarcacion.descripcion })
      this.cerrar_analisis()
    },
    eliminar_analisis (index) {
      this.form_analisis.splice(index, 1)
    },
    inicializar_cierre () {
      this.showAnalisis = false

      this.form_consumidor.gestion_id = 0
      this.form_consumidor.aceptacion_id = null
      this.form_consumidor.rectificacion_id = null

      this.form_tutela.gestion_id = 0
      this.form_tutela.resultado_id = 0

      this.form_cerrado.desistimiento_id = null
      this.form_cerrado.prorroga_id = null
      this.form_cerrado.respuesta = ''
      this.form_cerrado.resumen = ''
      this.form_cerrado.favorabilidad_id = null
      this.form_cerrado.fechaparacerrarprorroga = null

      this.form_analisis = []
    },
    input_prorroga (pProrrogaId) {
      this.form_cerrado.desistimiento_id = null
      this.form_cerrado.respuesta = ''
      this.form_cerrado.resumen = ''
      this.form_cerrado.favorabilidad_id = null
      this.form_cerrado.procorreoenvio_id = 0
      this.form_cerrado.fincorreoenvio_id = 0
      this.form_cerrado.fechaparacerrarprorroga = null
    },
    seleccionMarcacion () {
      this.switch1 ? this.opcion = 1 : this.opcion = 2
      this.items_marcacion = []
      this.form_duplicado.marcacion_id = null
      if (this.opcion === 1) {
        this.items.marcaciones.forEach(element => {
          if ((element.descripcion !== 'Si La Queja O Reclamo Fue Cerrada Por Falta De Competencia' &&
              element.descripcion !== 'Si La Queja Fue Cerrada Por Estar Repetida En Smartsupervision') || (element.id !== 485 && element.id !== 488)) {
            this.items_marcacion.push(element)
          }
        })
        this.showMarcacion = true
      } else if (this.opcion === 2) {
        this.showMarcacion = false
      }
      this.items_marcacion.forEach(element => {
        if (element.id === 482) {
          element.descripcion = 'Si La Queja O Reclamo Corresponde A Una Réplica, Es Decir, Si El Consumidor Financiero Solicita Reabrir La Queja O Reclamo Por Inconformidad Con La Respuesta Dada Por La Entidad Vigilada Y/o El Defensor Del Consumidor Financiero, Dentro De Los Dos (2) Meses Siguientes A La Fecha Final De Cierre De La Última Respuesta'
        }
      })
    },
    actualizarRepuestaFinal (estado) {
      this.estadoRespuestaFinalFile = estado
    }
  }
}
</script>
